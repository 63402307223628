import { defineStore } from "pinia";
import { CompaniesApi } from "@simbelapp/auth-sdk";
import type { ICompaniesStore } from "~~/utils/interfaces/companies-interfaces";
import { useCompaniesApi } from "~~/composables/companies/useCompaniesApi";
import { useSDKApi } from "~/composables/api/useSDKApi";
import { MicroserviceEnum } from "~/utils/enums/common.enums";
import { useAuthStore } from "~/store/auth/auth.store";

export const useCompaniesStore = defineStore("companies", {
  state: (): ICompaniesStore => {
    return {
      legal_entities: null,
      company_groups: null,
      company_infos: null,
      all_companies: null,
      aiIsActivated: false,
    };
  },
  getters: {},
  actions: {
    async fetchLegalEntities() {
      const companiesApi = useCompaniesApi();
      const { data } = await companiesApi.fetchCompanyLegalEntities();

      if (data.value) {
        this.legal_entities = data.value;
      }
    },

    /**
     * Checks whether AI functionality is activated for the currently authenticated company.
     *
     * This asynchronous method retrieves the company's ID from the authentication store and
     * calls the API endpoint to verify if AI is activated. If the company ID is present, it updates
     * the "aiIsActivated" property based on the API response. In case of an error during the API call,
     * the error is logged to the console.
     *
     * @returns A promise that resolves to void.
     */
    async checkIfAIIsActivated(): Promise<void> {
      const apiInstance = await useSDKApi(MicroserviceEnum.USERS, CompaniesApi);
      const authStore = useAuthStore();

      try {
        if (authStore?.jwt_payload?.company_id) {
          const response = await apiInstance.checkAIActivated({
            companyId: authStore.jwt_payload.company_id,
          });

          this.aiIsActivated = response?.aiActivated ?? false;
        }
      } catch (error) {
        console.error(error);
      }
    },

    async fetchCompanyGroups() {
      const companiesApi = useCompaniesApi();
      const { data } = await companiesApi.fetchCompanyGroups();
      if (data.value) {
        this.company_groups = data.value;
      }
    },

    async fetchCompanyInfos() {
      const companiesApi = useCompaniesApi();
      const { data } = await companiesApi.fetchCompanyInfos();
      if (data.value) {
        this.company_infos = data.value;
      }
    },

    async fetchAdminAllCompanies() {
      const companiesApi = useCompaniesApi();
      const { data } = await companiesApi.fetchAdminAllCompanies();

      if (data.value) {
        this.all_companies = data.value;
      }
    },
  },
});
